<template>
    <v-dialog v-model="active"
              persistent
              max-width="600px">
        <div class="iseq-top-up-modal">
            <div class="iseq-top-up-modal__header">
                <span class="iseq-top-up-modal__header__title">
                    Admin top up
                </span>
            </div>

            <div class="iseq-top-up-modal__description">
                <span class="iseq-top-up-modal__description__text">
                    This action will top up organization's account balance <strong>free of charge</strong>.
                </span>
            </div>

            <v-form v-model="isTopUpFormValid"
                    ref="topUpForm"
                    class="iseq-top-up-modal__form">

                <v-text-field v-model="topUpTarget.uuid"
                              label="Organization's uuid"
                              outlined
                              :disabled="organizationLock || step === 1"
                              :rules="targetUuidRules"></v-text-field>

                <v-text-field v-model="topUpTarget.amount"
                              label="Top up value"
                              outlined
                              :disabled="step === 1"
                              :rules="targetValueRules"></v-text-field>
            </v-form>

            <div v-if="step === 1"
                 class="iseq-top-up-modal__description">
                <span class="iseq-top-up-modal__description__text">
                    <strong>Organization name:</strong> {{organization.name}}<br />
                    <strong>Owner:</strong> {{`${organization.owners[0].name && organization.owners[0].name.length>0 ? (organization.owners[0].name + " (" + organization.owners[0].email + ")") : organization.owners[0].email}`}}<br />
                    <strong>Current account balance:</strong> {{organization.credits}}<br />
                    <strong>Organization status:</strong> {{organization.accountStatus}}<br /><br />
                    <strong>Top up value:</strong> {{topUpTarget.amount}}
                </span>
            </div>

            <div class="iseq-top-up-modal__buttons">
                <template v-if="step === 0">
                    <v-iseq-btn @click="closeModal"
                                type="red">
                        close
                    </v-iseq-btn>

                    <v-iseq-btn :disabled="!isTopUpFormValid"
                                @click="getOrganizationData">
                        Continue
                    </v-iseq-btn>
                </template>

                <template v-else>
                    <v-iseq-btn @click="step = 0"
                                type="red">
                        NOT correct (go back)
                    </v-iseq-btn>

                    <v-iseq-btn :disabled="!isTopUpFormValid"
                                @click="topUp">
                        Correct (top up)
                    </v-iseq-btn>
                </template>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import IseqButton from "@/components/ui/IseqButton"

export default {
    name: "TopUpModal",
    components: {
        "v-iseq-btn": IseqButton
    },
    props: {
        organizationUuid: {
            type: String
        }
    },
    data: function() {
        return {
            active: true,
            step: 0,
            topUpTarget: {
                uuid: "",
                amount: 0
            },
            organizationLock: false,
            isTopUpFormValid: false,
            targetUuidRules: [
                v => !!v && v.length>0 || "Target organization cannot be empty",
                v => /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/.test(v) || "This is not a valid uuid"
            ],
            targetValueRules: [
                v => !!v || "Value of the top up cannot be empty",
                v => /^[0-9]*$/.test(v) || "Value of the top up has to be a number"
            ]
        }
    },
    computed: {
        ...mapState('organization', ['organization'])
    },
    methods: {
        getOrganizationData() {
            this.$store.dispatch('organization/getOrganization', {uuid: this.topUpTarget.uuid});
            this.topUpTarget.amount = parseInt(this.topUpTarget.amount);
            this.step = 1;
        },
        topUp() {
            this.$store.dispatch('organization/topUpOrganization', this.topUpTarget);
            this.closeModal();
        },
        closeModal() {
            this.$emit('closed');
        }
    },
    created() {
        if (this.organizationUuid) {
            this.organizationLock = true;
            this.topUpTarget.uuid = this.organizationUuid;
        }
    }
}
</script>

<style lang="scss"
       scoped>
    .iseq-top-up-modal {
        display: flex;
        flex-direction: column;
        padding: 15px;
        background-color: white;
        color: var(--font-color);

        &__header {
            margin-bottom: 20px;

            &__title {
                font-size: 1.7em;
            }
        }

        &__description {
            margin: 0px 10px 10px 10px;

            &__text {

            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            margin: 0px 10px;
        }

        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .iseq-btn {
                margin-right: 10px;
            }
        }
    }
</style>